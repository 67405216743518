<template>
	<el-dialog class="dialog" :title="title" :visible.sync="visiable" v-if="visiable">
		<div v-loading="loading" class="dlalog-body">
			<el-form ref="form" :model="formData" label-width="180px">
				<el-row>
					<el-col :span="12">
						<el-form-item label="测点名称：" prop="name">
							<el-cascader
								@change="handleChange"
								v-model="formData.measId"
								:options="treeData"
								:disabled="dialogObj.type == 3"
								:props="{
									value: 'id',
									label: 'name',
									children: 'children',
									emitPath: false
								}"
							></el-cascader>
						</el-form-item>
					</el-col>
					<el-col :span="12" v-if="entId">
						<el-form-item label="容知传感器：" prop="brand">
							
							<el-select @change="handleChange2" v-model="formData.rzId" placeholder="请选择" filterable remote :remote-method="remoteMethod">
							    <el-option
							      v-for="item in treeData2"
							      :key="item.id"
							      :label="type==1002?item.name: item.measName"
							      :value="item.id">
							    </el-option>
							  </el-select>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="close()">关闭</el-button>
				<el-button type="primary" @click="submit()">确定</el-button>
			</div>
		</div>
	</el-dialog>
</template>
<script>
var regu = /^[0-9]+\.?[0-9]*$/;
import { dialogMixin } from '@m/dialogMixin';
import configApi from '@config/configApi';
export default {
	mixins: [dialogMixin],
	data() {
		return {
			loading: false,
			formData: {},
			treeData: [],
			treeData2: [],
			entId:'',
			type:1000,
			title:'新增绑定'
		};
	},
	props: {},
	watch: {},
	methods: {
		
		remoteMethod(query) {
			if (query !== '') {
				this.loading = true;
				let url
				if(this.type==1002){
					url = '/backend-api/eqp/set/unit/meas/rongzi/queryRzTempMeasList'
				}else{
					url = '/backend-api/eqp/set/unit/meas/rongzi/queryRzMeasList'
				}
				this.$get(url, {
					nameKeword: query,
					page: 1,
					pageSize: 99,
					ent_id:this.entId
				}).then(res => {
					if (res.code != 1000) {
						this.$message.error(res.msg);
						return;
					}
					this.loading = false;
					this.treeData2 = res.data;
				});
			} else {
				this.treeData2 = [];
			}
		},
		//打开弹窗
		open() {
			this.formData = {};
			//
			if(this.dialogObj.type == 3){
			    this.formData = this.dialogObj;
				this.title = this.dialogObj.measName+'绑定'
				// this.entId = 'ca74abae083a60772e6c97a208b912f0'
				this.entId = this.dialogObj.entId;
				
				let url
				if(this.type==1002){
					url = '/backend-api/eqp/set/unit/meas/rongzi/queryRzTempMeasList'
				}else{
					url = '/backend-api/eqp/set/unit/meas/rongzi/queryRzMeasList'
				}
				
				this.$get(url,{page:1,pageSize:99,ent_id:this.entId}).then(res => {
					if (res.code != 1000) {
						this.$message.error(res.msg);
						return;
					}
					this.treeData2 = res.data;
				});
			}
			this.$nextTick(() => {
				this.$refs.form.clearValidate();
			});
			this.getmeasList();
		},
		getmeasList() {
			this.$get('/backend-api/eqp/set/meas/list?meas_include=1&meas_suffix=1').then(res => {
				if (res.code != 1000) {
					this.$message.error(res.msg);
					return;
				}
				this.treeData = res.data;
			});
		},
		handleChange(value) {
			let data = this.getDataById(value, this.treeData);
			if (data) {
				let url
				if(data.type==1002){
					this.type = 1002;
					url = '/backend-api/eqp/set/unit/meas/rongzi/queryRzTempMeasList'
				}else{
					this.type = 1000;
					url = '/backend-api/eqp/set/unit/meas/rongzi/queryRzMeasList'
				}
				this.entId = data.ent_id;
				this.$get(url,{page:1,pageSize:99,ent_id:data.ent_id}).then(res => {
					if (res.code != 1000) {
						this.$message.error(res.msg);
						return;
					}
					this.treeData2 = res.data;
				});
			} else {
			    console.log("未找到ID为", value, "的数据");
			}
		},
		handleChange2(value) {
			let data = this.getDataById2(value, this.treeData2);
			
			
			
			if (data) {
				this.formData.path = data.path;
			} else {
			    console.log("未找到ID为", value, "的数据");
			}
		},
		

		// 通过ID获取树结构下的某一组数据
		getDataById(id, nodes) {
		    for (var i = 0; i < nodes.length; i++) {
		        var node = nodes[i];
		        if (node.id === id) {
		            return node;
		        }
		        if (node.children && node.children.length > 0) {
		            var result = this.getDataById(id, node.children);
		            if (result) {
		                return result;
		            }
		        }
		    }
		    return null; // 如果未找到匹配的节点，则返回null
		},
		// 通过ID获取树结构下的某一组数据
		getDataById2(id, nodes) {
		    for (var i = 0; i < nodes.length; i++) {
				 var node = nodes[i]
		       if (node.id === id) {
		           return node;
		       }
		    }
		    return null; // 如果未找到匹配的节点，则返回null
		},
		//提交表单
		submit() {
			this.$refs['form'].validate(valid => {
				if (valid) {
					this.loading = true;
					if(this.type==1000){
						this.formData.type = 0
					}else{
						this.formData.type = 1
					}
					this.$postJson('/backend-api/eqp/set/unit/meas/rongzi/save', this.formData).then(res => {
						if (res.code == 1000) {
							this.loading = false;
							this.$message.success(res.msg);
							this.$parent.list();
							this.close();
						} else {
							this.loading = false;
							this.$message.error(res.msg);
						}
					});
				} else {
					return false;
				}
			});
		},
		//关闭弹窗
		close() {
			this.visiable = false;
		}
	}
};
</script>
<style lang="scss" scoped>
.dialog {
	::v-deep .cascader {
		display: block;
	}
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
	-webkit-appearance: none;
}
::v-deep input[type='number'] {
	-moz-appearance: textfield;
}
</style>
