<template>
	<el-dialog class="dialog" title="新增wincc测点" :visible.sync="visiable">
		<div v-loading="loading" class="dlalog-body">
			<el-form ref="form" :model="formData" :rules="rules" label-width="180px">
				<el-row>
					<el-col :span="12">
						<el-form-item label="查看企业：" prop="entId">
							<el-select v-model="formData.entId" clearable :popper-append-to-body="false"
								placeholder="请选择">
								<el-option v-for="item in ents" :key="item.abbr" :label="item.abbr"
									:value="item.id"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="转速点编码：" prop="speedMqttPointCode"><el-input
								v-model="formData.speedMqttPointCode"></el-input></el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="转速点名称：" prop="speedMqttPointDesc"><el-input
								v-model="formData.speedMqttPointDesc"></el-input></el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="电流点编码：" prop="currentMqttPointCode"><el-input
								v-model="formData.currentMqttPointCode"></el-input></el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="电流点名称：" prop="currentMqttPointDesc"><el-input
								v-model.number="formData.currentMqttPointDesc"></el-input></el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="close()">关闭</el-button>
				<el-button type="primary" @click="submit()">确定</el-button>
			</div>
		</div>
	</el-dialog>
</template>
<script>
	var regu = /^[0-9]+\.?[0-9]*$/;
	import {
		dialogMixin
	} from '@m/dialogMixin';
	import configApi from '@config/configApi';
	export default {
		mixins: [dialogMixin],
		data() {
			return {
				title: '',
				loading: false,
				formData: {},
				ents: [],
				rules: {
					entId: [{
						required: true,
						message: '请选择企业',
						trigger: 'blur'
					}, ],
					speedMqttPointCode: [{
							required: true,
							message: '请选择转速点编码',
							trigger: 'blur'
						},

					],
					speedMqttPointDesc: [{
							required: true,
							message: '请选择转速点名称',
							trigger: 'blur'
						},

					],
					currentMqttPointCode: [{
							required: true,
							message: '请选择电流点编码',
							trigger: 'blur'
						},

					],
					currentMqttPointDesc: [{
							required: true,
							message: '请选择电流点名称',
							trigger: 'blur'
						},

					],
				}
			};
		},
		props: {},
		watch: {},
		methods: {
			//打开弹窗
			open() {
				this.formData = {};
				if (this.dialogObj.type == 2) {
					this.get();
				}
				this.$nextTick(() => {
					this.$refs.form.clearValidate();
				});
				this.getEnts();
			},

			//获取企业列表
			getEnts() {
				this.$get('/backend-api/sys/ent/list', {
					size: 99
				}).then(res => {
					if (res.code == 1000) {
						this.ents = res.data.records;
					} else {
						this.$message.error(res.msg);
					}
				});
			},

			recurse(n, d) {
				// debugger
				for (let i = 0; i < n.length; i++) {
					if (n[i].id == d) {
						return n[i].name;
					} else {
						for (let e = 0; e < n[i].children.length; e++) {
							if (n[i].children[e].id == d) {
								return n[i].children[e].name;
							}
						}
					}
				}
			},
			//提交表单
			submit() {
				this.$refs['form'].validate(valid => {
					if (valid) {
						this.loading = true;
						this.$postJson('/backend-api/eqp-set-unit-meas-mqtt', this.formData).then(res => {
							if (res.code == 1000) {
								this.loading = false;
								this.$message.success(res.msg);
								this.$parent.list();
								this.close();
							} else {
								this.loading = false;
								this.$message.error(res.msg);
							}
						});
					} else {
						return false;
					}
				});
			},
			//关闭弹窗
			close() {
				this.visiable = false;
			}
		}
	};
</script>
<style lang="scss" scoped>
	.dialog {
		::v-deep .cascader {
			display: block;
		}
	}

	::v-deep input::-webkit-outer-spin-button,
	::v-deep input::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}

	::v-deep input[type='number'] {
		-moz-appearance: textfield;
	}
</style>